#industries {
  @apply bg-light-gray-color text-dark-text-color px-5 py-18 lg:px-0 lg:py-20;
}

.industriesSection h2 {
  @apply text-dark-text-color tracking-tightest leading-9 lg:leading-52;
}

.industriesSection {
  @apply br-container pl-container-margin mx-auto;
}

.industriesSectionTitle {
  @apply w-auto lg:w-3/4;
}

.industriesSectionTitle p {
  @apply text-2xs lg:text-base mt-3.5 lg:mt-2 leading-23 lg:leading-28 lg:w-3/5;
}

.industriesList {
  @apply mt-14 lg:pl-4 flex flex-col gap-8 lg:gap-0 lg:flex-row;
}

.industryListNavigation {
  @apply flex flex-row flex-wrap lg:flex-col gap-3.5 lg:gap-6 text-base lg:text-xl lg:pr-48 lg:w-2/5 tracking-snug pl-1 lg:pl-0;
}

.industryListNavigation li:hover {
  @apply font-medium;
}

.industryCards {
  @apply flex-grow;
}

.industryCaseInfo {
  @apply py-7 lg:py-12 px-5 lg:px-0 lg:pl-12 lg:pr-container-margin absolute bottom-0 right-0 w-auto lg:w-2/3 bg-white rounded-md mx-4 mb-4 lg:m-0 lg:rounded-none lg:rounded-tl-sm;
}

.industryCaseInfo p {
  @apply text-3xs lg:text-2xs leading-21 mt-2;
}

.industryCaseInfo h5 {
  @apply font-medium text-2xs lg:text-lg leading-21 lg:leading-28 tracking-tight;
}

.industryImage {
  @apply w-full h-full object-fill;
}

.industryCaseInfo img {
  @apply mt-8 lg:mt-16 lg:h-auto lg:w-auto w-auto scale-75 -translate-x-3 lg:translate-x-0 lg:scale-100;
}
