#products {
  @apply bg-black-gray px-5 py-18 lg:px-0 lg:py-20;
}

.productsSection {
  @apply br-container mx-auto text-white;
}

.productSectionTitle {
  @apply container mx-auto;
}

.productSectionTitle p {
  @apply lg:text-base mt-3.5 lg:mt-2 leading-23 lg:leading-28 lg:w-3/5;
}

.productsList {
  @apply mt-20 flex flex-col lg:flex-row gap-5 lg:gap-0 pl-container-margin;
}

.productsListNavigation {
  @apply mt-5 lg:block hidden;
}

.productsListNavigation a {
  @apply mt-2 py-8 pl-9 block;
}

.productCardInfo p {
  @apply text-2xs leading-21 lg:text-base tracking-snug lg:leading-28 flex-grow flex-auto;
}

.productCardInfo p strong {
  @apply font-medium;
}

.productCardInfo {
  @apply px-5 lg:pl-9 py-7 lg:pr-container-margin flex-grow flex flex-col;
}

.productCardLogo {
  @apply mt-9;
}

.productCardLogo svg {
  @apply h-6 w-auto lg:h-auto;
}

.spacer {
  @apply flex-grow;
}
