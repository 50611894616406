#whyMonofor {
  @apply bg-darkest-gray-color text-white px-5 py-18 lg:px-0 lg:py-20;
}

.whyMonoforSection {
  @apply container mx-auto;
}

.whyMonoforTitle h2 {
  @apply text-white tracking-tightest leading-9 lg:leading-52;
}

.whyMonoforTitle {
  @apply pl-1 lg:pl-0 w-auto lg:w-2/3;
}

.whyMonoforTitle p {
  @apply text-2xs lg:text-base mt-3.5 lg:mt-2 leading-23 lg:leading-28;
}

.featureCards {
  @apply mt-14 lg:mt-20 flex flex-col gap-8;
}

.featuresRow {
  @apply flex flex-col lg:flex-row gap-8;
}

.featureCard {
  @apply rounded-sm bg-lighter-green-color text-dark-text-color p-6 lg:p-8;
}

.featureCard h5 {
  @apply text-xl font-medium tracking-tighter leading-28;
}

.featureCard p {
  @apply text-3xs lg:text-2xs mt-4 lg:mt-5 tracking-snug leading-23;
}

.featureCard p:first-of-type {
  @apply mt-3;
}

.integrationsWall {
  mask-image: linear-gradient(
    89.99deg,
    rgba(255, 255, 255, 0) 0.01%,
    #ffffff 31.77%,
    #ffffff 71.86%,
    rgba(255, 255, 255, 0) 99.98%
  );
  @apply overflow-hidden mt-3;
}

.integrationsWallImage {
  height: 121px;
  width: 2970px;
  background: url('/images/integrations-list.png') repeat-x 0 50%;
  background-size: contain;
  animation: slide 60s linear infinite;
}

.featuresWall {
  mask-image: linear-gradient(
    89.99deg,
    rgba(255, 255, 255, 0) 0.01%,
    #ffffff 31.77%,
    #ffffff 71.86%,
    rgba(255, 255, 255, 0) 99.98%
  );
  @apply overflow-hidden flex flex-col gap-1 mt-2;
}

.featuresWallImage {
  height: 37px;
  background-size: contain;
}

.featuresWallImage.firstRow {
  width: 2808px;
  height: 37px;
  background: url('/images/features-1.png') repeat-x 0 50%;
  background-size: contain;
  animation: slideFeatures1 60s linear infinite;
}

.featuresWallImage.secondRow {
  width: 2781px;
  height: 37px;
  background: url('/images/features-2.png') repeat-x 0 50%;
  background-size: contain;
  animation: slideFeatures2 60s linear infinite;
}

.featuresWallImage.thirdRow {
  width: 2781px;
  height: 37px;
  background: url('/images/features-2.png') repeat-x 0 50%;
  background-size: contain;
  animation: slideFeatures3 60s linear infinite;
}

.modularized {
  @apply mx-0 p-0 pt-7 pb-2 w-auto lg:w-1/3;
  grid-area: a;
}

.modularized img {
  @apply pl-6 lg:pl-8;
}

.integrations {
  @apply mx-0 p-0 flex-shrink w-auto lg:w-2/3;
}

.integrationsInfo {
  @apply mx-6 lg:mx-8 pb-8 mt-7 max-w-xl;
}

.features {
  @apply mx-0 p-0 pt-3 lg:w-2/3;
}

.cloud {
  @apply mx-0 p-0 pt-7 lg:w-1/3;
}

.cloud img {
  @apply pl-6 lg:pl-8;
}

.modularizedInfo {
  @apply mx-6 lg:mx-8 pb-8 mt-7 w-4/5;
}

.featuresInfo {
  @apply mx-6 lg:mx-8 pb-8 mt-7 max-w-xl;
}

.cloudInfo {
  @apply mx-6 lg:mx-8 pb-8 mt-7 w-4/5;
}

.roundedOutlineButton {
  @apply mt-12 py-1.5 px-5 font-medium text-2xs text-dark-text-color rounded-default border border-dark-text-color tracking-tighter;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1650px, 0, 0); /* The image width */
  }
}

@keyframes slideFeatures1 {
  0% {
    transform: translate3d(-1872px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0); /* The image width */
  }
}

@keyframes slideFeatures2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1854px, 0, 0); /* The image width */
  }
}

@keyframes slideFeatures3 {
  0% {
    transform: translate3d(-1854px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0); /* The image width */
  }
}
