#testimonials {
  @apply bg-lightest-gray-color pt-14 pb-16 text-dark-text-color;
}

.testimonialsContainer {
  @apply mx-auto container px-5 md:px-0;
}

.testimonialsTitle {
  @apply flex flex-row place-content-between items-center gap-5;
}

.gartnerLogo {
  @apply w-28 md:w-auto h-auto;
}

.testimonialsTitle h2 {
  @apply text-dark-text-color tracking-tightest text-xl md:text-2xl leading-28 lg:leading-52;
}

.testimonialItem {
  @apply bg-white rounded-sm shadow-sm p-6 flex flex-col gap-5 text-xs;
}

.testimonialItem blockquote {
  @apply leading-6 tracking-tight block max-w-full;
}
.testimonialItem blockquote:before {
  @apply text-dark-text-color inline text-xs;
  content: '“';
}
.testimonialItem blockquote:after {
  @apply text-dark-text-color inline text-xs;
  content: '”';
}

.testimonialItem figcaption {
  @apply font-medium text-4xs;
}

.testimonialItemBottomBar {
  @apply flex flex-row place-content-between items-center;
}
